import React from "react";
import "./PrivacyBody.css";
import about_2 from "../../images/useful_images_svg/anurag.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";
const PrivacyBody = () => {
  return (
    <div className="privacy">
      <div className="privacy-header">
        {" "}
        <div className="overlay"></div>
        <div className="content">
          <h4>Privacy Policy</h4>
        </div>
      </div>

      <div className="privacy-content">


	<p>THIS PRIVACY POLICY (“PRIVACY POLICY“) SETS FORTH THE MANNER OF COLLECTION, USE AND DISCLOSURE OF INFORMATION GATHERED ON THE PLATFORM. PLEASE READ THIS PRIVACY POLICY CAREFULLY. BY CONTINUING TO USE THE SERVICES, OR ACCESS THE PLATFORM YOU AGREE TO THE TERMS OF THIS PRIVACY POLICY. IF YOU DO NOT AGREE TO SAME, YOU MAY NOT ACCESS OR USE THE PLATFORM OR AVAIL THE SERVICES. Access and use of the Platform is subject to the Terms and Conditions (“Terms“) accessible on the Platform.</p>


	<p>Doctorspectra Technological Services Private Limited , a private limited company with its registered office at House no.10, Prestige Oasis, Rajanukunte, Dodaballapur road, Bangalore – 560064, Karnataka (hereinafter shall be referred to as “Company”, “Doctorspectra”, “We”, “Us”, “Our” and terms of similar meaning) owns and operates:
	  <ul>
	    <li>a) https://www.doctorspectra.com/including any subdomains thereof (collectively, “Site“);</li>
	    <li>b) all related widgets, tools, applications, data, software, APIs, mobile, tablet and other smart device applications including the ‘Doctorspectra’ application (collectively, “Application“); and</li>
	    <li>c) services as provided by the Company from time to time.</li>
	  </ul>
	  The Site and the Application together are hereinafter collectively referred to as the “Platform”
	</p>

	<p>This Privacy Policy does not apply to the websites of our business partners, corporate affiliates or to any other third parties, even if their websites are linked to the site. We recommend you review the respective privacy statements of the other parties with whom you interact.</p>
	
	<p>By accessing the Platform or otherwise using the Platform, you (hereinafter referred to as “You”, “Your”, “User”, “Guest/s” as applicable) expressly consent to Our collection, storage, use and disclosure of the Information (as defined hereunder) in accordance with the terms of this Privacy Policy.</p>

	<p>As we update, improve and expand the Platform, this Privacy Policy may change at Our sole discretion without notice, so please refer back to it periodically as You will be deemed to be governed by the terms of such modified Privacy Policy, as it comes into effect from time to time. By using Our Platform, you are deemed to have read, acknowledged and accepted all the provisions stated in this Privacy Policy, including any changes that we may make from time to time.</p>

	<h4>TYPE OF INFORMATION COLLECTED</h4>
	<p>1.1 To access/utilise the Platform, the Users are required to provide certain personal, non-personal and other information, as indicated below (such information and correspondences hereinafter collectively referred to as “Information”)</p>
	<ul>
	  <li>a). Personal Information: We may require You to provide us with certain information that personally identifies You (“Personal Information“). Personal Information includes the following categories of information: (i) Contact Data (such as your e-mail address, phone number and Account password); and (ii) Demographic Data (such as your gender, your date of birth and your zip code). If you communicate with Us for example, by e-mail or letter, any information provided in such communication may be collected as Personal Information. Please note that the above list is not an exhaustive list and is only an illustrative list and is without prejudice to Our right to seek further Personal Information, as may be required.
	    <br/>
	    Any Personal Information provided by You will not be considered as sensitive if it is freely available and/or accessible in the public domain. Further, any comments, messages, scribbles etc. posted/uploaded/conveyed/communicated by You on the public, through reviews, blogs, online chat or instant messages, discussion boards, forums, user testimonials or comments sections of the Platform becomes published content and is not considered personally identifiable information subject to this Privacy Policy. We will not be responsible for the accuracy or filtration of any of the content posted on Our Platform by any User, however, We will be entitled to remove any content posted by any User on Our Platform which We, in Our sole discretion, consider offensive, discriminatory, racist, defamatory or inappropriate in nature.</li>

	  <li>b) Non-Personal Information: When You visit the Platform, we may collect certain non-personal information such as domain and host from which You access the internet, Your internet protocol address, operating system, browser type, and internet service provider. This type of information does not identify You personally.</li>
	  <li>c) Automatic Information: To improve the responsiveness of the Platform for You, We may use “cookies”, or similar electronic tools to collect information to understand the User’s individual interests. These server logs may include information such as Your web request, Internet Protocol address, browser type, browser language, the date and time of Your request and one or more cookies that may uniquely identify Your browser. We use cookies primarily for user authentication.</li>
	  <li>d) Mobile: When You use the Platform through Your mobile, we may receive information about Your location and Your mobile device, including a unique identifier number for Your device. We may use this information to provide You with suggestions in respect of services if required</li>
	  <li>e) User communications: When You send emails or other communications to Us, we may retain those communications in order to process Your inquiries, respond to Your requests and improve our services.</li>
	  <li>f) Information on behalf of Children: If You are a parent or legal guardian of a minor child, You may access the Platform on behalf of such minor child. You also expressly undertake that You have the authority to share such information on behalf of Your child. Any information that You provide while using the Platform on behalf of Your minor child will be treated as Personal Information unless otherwise provided herein.</li>
	</ul>

	<p>1.2) In case You choose to decline to submit any Information, We may not be able to provide You access to the Platform or certain aspects of the Platform. We will not be liable and/or responsible for the denial of providing access to the Platform to You on account of inadequacy of Information provided by You.</p>
	<p>1.3) When You register with the Platform, We may contact You from time to time about updation of Information to provide You such features that We believe may benefit/interest You.</p>

	<h4>USE OF INFORMATION</h4>
	<p>2.1)  We do not sell, trade, or otherwise transfer to third parties Your Information. This does not include trusted third parties who assist Us in operating Our Platform, conducting Our business, or servicing You. We may also release Your information when We believe release is appropriate to comply with the law, enforce Our Platform policies, or protect Ours’ or others’ rights, property, or safety. However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.</p>

	<p>2.2) We may process the Information You provide in the following manner:
	  <ul>
	    <li>a)  To personalize your experience; and/or</li>
	    <li>b)  The Company shall be entitled to process the Information in accordance with this Privacy Policy and as permitted by the applicable law; and/or</li>
	    <li>c)  To improve our services and offerings; and/or</li>
	    <li>d)  To contact You and to customise Our communication with You and the marketing material we share with You; and/or</li>
	    <li>e)  Your information, whether public or private shall be used to analyse and suggest any other related product/service that shall be suitable for you; and/or</li>
	    <li>f)  The email address provided by You, may be used to send You information and updates about Us; and/or</li>
	    <li>g)  To avoid fraud and other prohibited or illegal activities; and/or</li>
	    <li>h)  To protect the security or integrity of the Platform, Our business, services and other users; and/or</li>
	    <li>i)  To allow our professionals, business partners to present customised messages to You; and/or</li>
	    <li>j)  To publish any testimonials or reviews that You may have provided on the Platform; and/or</li>
	    <li>k)  We may disclose Information, in good faith, wherein the access, use, preservation or disclosure of such information is reasonably necessary to:</li>
	    <ul><li>(i) satisfy any applicable law, regulation, legal process or enforceable governmental request,</li><li>(ii) detect, prevent, or otherwise address fraud, security or technical issues, or </li><li>(iii) protect against imminent harm to the rights, property or safety of the Company, its users or the public as required or permitted by law.</li>
	    </ul>
	  </ul>
	</p>
	<br/>
	<h4>SHARING OF INFORMATION</h4>
	<p>For the purposes of Our Platform, We shall be sharing Your Personal Information with any of Our business partners, administrators, vendors, agents, service providers or affiliates for legitimate business purposes (including operating the services, and providing services to You), in accordance with applicable law. Additionally, in the event of an audit, reorganization, merger, sale, joint venture, assignment, transfer or other disposition of all or any portion of Our business, assets or stock (including in connection with any bankruptcy or similar proceedings), We may share/transfer/assign the Information we have collected to the relevant third party.</p>

	<h4>DATA RETENTION</h4>
	<p>We retain Personal Information for as long as needed or permitted in light of the purpose(s) for which it was obtained and consistent with applicable law. The criteria for determining the duration for which We will keep Your Personal Information are as follows: (i) We will retain copies of Your Personal Information in a form that permits identification only for as long as is necessary in connection with the purposes set out in this Privacy Policy, including for the length of time We have an ongoing relationship with You and provide the Platform to You; (ii) if there is a legal obligation to which We are subject (for example, certain laws require Us to keep records of Your transactions for a certain period of time before We can delete them); or (iii) keeping in view the legitimate interests of the Company (litigation or regulatory investigations).</p>

	<h4>SECURITY</h4>
	<p>We use reasonable security measures to protect Information from unauthorized access, maintain data accuracy and help ensure the appropriate use/disclosure of Information. We host the Platform in a secure server environment that uses firewalls and other advanced technology to prevent interference or access from outside intruders.</p>

	<h4>LINKS TO THIRD PARTY SITES</h4>
	<p>The Platform may include links to third party applications/websites/Platforms (“Third Party Sites”). Such Third Party Sites are governed by their respective privacy policies, which are beyond Our control. Once You leave our Platform, use of any information You provide is governed by the privacy policy of the operator of the Third Party Site You are visiting. That policy may differ from Ours. If You can’t find the privacy policy of any of these Third Party Sites, You should contact the relevant Third Party Site directly for more information.</p>

	<p>We do not provide any Personal Information or personally identifiable information to third party websites / advertisers / ad-servers without Your consent.</p>

	<h4>ADVERTISING</h4>
	<p>We use third-party service providers to display ads on Our behalf across the internet and sometimes on this Platform. They may collect anonymous information about Your visits to Our Platform. They may also use information about Your visits to this and other websites to target advertisements for goods and services.</p>

	<p>This anonymous Information is collected through the use of a pixel tag, which is standard technology used by most major websites in the industry. No personally identifiable information is collected or used in this process.</p>

	<h4>YOUR CONSENT</h4>
	<p>By using the Platform and/or by providing Your Information, You consent to the collection and use of such Information in accordance with this Privacy Policy, including but not limited to Your consent for sharing Your Information as per this Privacy Policy. You specifically agree and consent to us collecting, storing, processing, transferring and sharing Information (including Personal Information) related to You with third parties, professionals or to registered business partner/users for the purposes as set out in this Privacy Policy.</p>

	<h4>CONTACTING US</h4>
	<p>If there are any questions regarding this Privacy Policy, please write to us at contactus@doctorspectra.com. If you are not satisfied with the response, please write to the Grievance Officer.</p>

	<p>Any complaints, abuse or concerns with regards to content and or comment or breach of this Privacy Policy shall be immediately informed to the designated Grievance Officer as mentioned below via in writing or through email.</p>

	<p>The Grievance Officer shall acknowledge the complaint within 24 (twenty four) hours and redress the complaints within 15 (fifteen) days from the date of receipt of complaint.</p>

	<h5>Details of the Grievance Officer:</h5>
	<ul>
	  <li>Name: Rati Gugnani</li>
	  <li>Designation: CEO</li>
	  <li>Email: rati@doctorspectra.com</li>
	</ul>

	<p>Thank you for using the Platform!</p>
	


      </div>
    </div>
  );
};

export default PrivacyBody;
