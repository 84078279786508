import React from "react";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import PrivacyBody from "../components/Privacy/PrivacyBody";
const Privacy = () => {
  return (
    <div>
      <Navbar />
      <PrivacyBody />
      <Footer />
    </div>
  );
};

export default Privacy;
